package com.rooam.resy.components.routes.check

import co.rooam.components.receipt.ReceiptLine
import co.rooam.components.receipt.RooamReceipt
import co.rooam.components.tab.live.OrderedItem
import co.rooam.components.tab.live.OrderedItems
import co.rooam.components.tab.tips.Tip
import co.rooam.utilities.functions.minus
import co.rooam.utilities.functions.sum
import co.rooam.utilities.functions.tipRate
import csstype.None
import csstype.integer
import csstype.px
import csstype.rem
import kotlinx.browser.localStorage
import kotlinx.js.Record
import kotlinx.js.jso
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Container
import mui.material.FormControlColor
import mui.material.Paper
import mui.material.PaperVariant
import mui.material.Size
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.sx
import org.w3c.dom.url.URLSearchParams
import react.VFC
import react.create
import react.query.QueryFunctionContext
import react.query.QueryKey
import react.query.UseQueryOptions
import react.query.useQuery
import react.router.dom.useSearchParams
import react.router.useLocation
import react.router.useNavigate
import react.router.useParams
import react.useCallback
import react.useMemo
import react.useState
import kotlin.js.Promise


data class Tips(val tips: List<Tip>, val defaultTip: Tip)

data class Tip(val percentage: Int)

val Check = VFC {
	val navigate = useNavigate()
	val theme = useTheme<Theme>()
	val (queryParams, _) = useSearchParams()
	val params = useParams()
	val CheckQueryKey = QueryKey<QueryKey>("CheckFeed", queryParams, params)
	val check =
		useQuery(CheckQueryKey, ::checkFunction, jso<UseQueryOptions<CheckData, Throwable, CheckData, QueryKey>> {
			refetchInterval = 10000

		}
		)
	//get data from place
	val (tips, setNewTips) = useState(Tips(listOf(Tip(18), Tip(20), Tip(22)), Tip(20)))

	val (tipValue, setTipValue) = useState(
		tips.defaultTip.percentage to tipRate(
			sum(check.data?.totals?.subTotal, check.data?.totals?.discount),
			tips.defaultTip.percentage
		)
	)

	val tipsMap = useMemo(check.data?.totals?.subTotal){
		tips.tips.associate { it.percentage to tipRate(check.data?.totals?.subTotal, it.percentage) }
	}
	val currentTipValue = useMemo(tipValue,check.data?.totals?.subTotal,check.data?.totals?.discount){
		if (tipValue.first ==-1){
			tipValue.second
		}else
			tipRate(sum(check.data?.totals?.subTotal, check.data?.totals?.discount), tipValue.first)
	}
	val calculateTotal = useMemo(check.data?.totals?.subTotal, currentTipValue) {
		calculateTotal(check.data?.totals, currentTipValue)
	}
	if (check.isLoading) {
		Container {
			Typography {
				variant = TypographyVariant.h1
				+"Loading Your check"
			}
		}
	} else {
		Container {
			Typography {
				variant = TypographyVariant.h1
				+"Check nr: ${check.data?.number}"
			}

			OrderedItems {
				items = check.data?.items?.toTypedArray() ?: emptyArray()

			}
			Button {
				variant = ButtonVariant.outlined
				size = Size.small
				fullWidth = true
				sx {
					textTransform = None.none
				}
				+"Split The Check"
			}
			Typography {
				sx {
					marginTop = 20.px
				}
				variant = TypographyVariant.h2
				+"Select Your Tip."
			}
		}
		Container {
			sx {
				marginBottom = 10.px
			}
			Tip {
				this.tips = tipsMap
				chosenTip = tipValue
				onTipChange = { tip, value ->
					setTipValue(tip to value)
					console.log(tip, value) }
				tipColor = FormControlColor.primary
				divider = Typography.create {
					sx {
						marginTop = 4.px
						marginBottom = 4.px
						fontSize = 12.px
						fontWeight = integer(500)
					}
					+"Or Custom Tip Amount"
				}
			}
		}
		Container {
			RooamReceipt {
				val totals = check.data?.totals
				this.tip = currentTipValue
				this.taxesFees = sum(
					sum(
						totals?.tax,
						totals?.fee
					),
					totals?.serviceCharges
				)
				this.serviceCharges = totals?.serviceCharges
				this.credit = totals?.credit
				this.tax = totals?.tax ?: "0.00"
				this.fees = totals?.fee ?: "0.00"
				this.subtotal = totals?.subTotal ?: "0.00"
				this.discount = totals?.discount ?: "0.00"

			}
		}
		Paper {
			variant = PaperVariant.outlined
			square = true
			sx {
				paddingTop = 10.px
				paddingBottom = 10.px
				marginTop = 5.px
			}
			Container {
				ReceiptLine {
					title = "Total:"
					value = calculateTotal
					sx {
						fontSize = 1.rem
						color = theme.palette.text.primary
						fontWeight = integer(600)
					}
				}
			}
		}
		Container {
			sx {
				marginTop = 5.px
			}
			Button {
				variant = ButtonVariant.contained
				sx {
					textTransform = None.none
				}
				fullWidth = true
				size = Size.large
				onClick={
					navigate("../pay")
				}
				+"Checkout"
			}
		}
	}
}


data class Item(
	override val name: String,
	override val quantity: Int,
	override val pricePerUnit: String,
	override val price: String
) : OrderedItem

data class CheckData(
	val number: String,
	val items: List<Item>,
	val totals: Totals

)

data class Totals(
	val subTotal: String = "0.00",
	val discount: String = "0.00",
	val tax: String = "0.00",
	val taxesFees: String = "0.00",
	val fee: String = "0.00",
	val tip: String = "0.00",
	val serviceCharges: String?="0.00",
	val credit: String?="0.00",
)

var counter: Int = 0
fun checkFunction(context: QueryFunctionContext<QueryKey, *>): Promise<CheckData> {
	console.log(context)
	console.log(context.queryKey)
	console.log(context.pageParam)
	val queryKey = context.queryKey.unsafeCast<Array<Any>>()
	val searchParams = queryKey[1].unsafeCast<URLSearchParams>()
	val pathParams = queryKey[2].unsafeCast<Record<String, String>>()
	console.log(queryKey)
	console.log(searchParams)
	console.log(pathParams)
	val checkNumber = searchParams.get("check")
	console.log(checkNumber)
	counter++
	return Promise.resolve(
		CheckData(
			checkNumber ?: "Searching",
			(1..counter).map {
				Item("Beer @${pathParams["placeCode"]} x${it}", it, "10.00", "${it}0.00")
			},
			Totals("${counter* (counter+1)/2}0.00")
		)
	)
}

private fun calculateTotal(totals: Totals?, tipValue: String) =
	if (totals != null) {
		with(totals!!) {
			minus(sum(sum(sum(sum(subTotal, tax), fee), serviceCharges), tipValue), credit)
		}
	} else tipValue
