package com.rooam.resy.components.expresscheckout


import csstype.TextAlign
import csstype.px
import emotion.react.css
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Stack
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.responsive
import react.VFC
import react.dom.html.ReactHTML.fieldset
import react.dom.html.ReactHTML.legend

val ExpressCheckout = VFC {
	val theme = useTheme<Theme>()
	fieldset {
		css {
			borderRadius = theme.shape.borderRadius
			borderColor = theme.palette.divider
			marginBottom = 20.px
		}
		legend {
			css {
				textAlign = TextAlign.center
				paddingRight = 10.px
				paddingLeft = 10.px
				color = theme.palette.text.secondary
			}
			+"Express Checkout"
		}
		Stack {
			spacing = responsive(2)
			Button {
				variant = ButtonVariant.contained
				fullWidth = true
				+"Google Pay placeholder"
			}

			Button {
				variant = ButtonVariant.contained
				fullWidth = true
				+"Apple Pay placeholder"
			}
		}
	}

}

// /api/somethig/
