package com.rooam.resy.components.footer

import csstype.AlignItems
import csstype.Auto
import csstype.Display
import csstype.FlexDirection
import csstype.Margin
import csstype.TextAlign
import csstype.em
import csstype.px
import emotion.react.css
import kotlinext.js.require
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.sx
import react.VFC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.footer
import react.dom.html.ReactHTML.img
import kotlin.js.Date

val Footer = VFC {
	val theme = useTheme<Theme>()
	val image = require("./img/footer/resy-rooam-lockup.png")
	footer {
		css {
			paddingTop = 100.px
			display = Display.flex
			flexDirection = FlexDirection.column
			alignItems = AlignItems.center
			textAlign = TextAlign.center
		}
		img {
			css {
				width = 150.px
				marginBottom = 10.px
			}
			src = image
		}
		Typography {
			variant = TypographyVariant.body1
			sx {
				fontSize = 11.px
			}
			+"ResyPay - "
			Link {
				color = "inherit"
				underline = LinkUnderline.none
				href = "https://rooam.co"
				+"Powered by Rooam"
			}
		}
		Typography{
			variant = TypographyVariant.body2
			sx{
				color=theme.palette.text.disabled
				fontSize=11.px
			}
			+"© Copyright ${Date().getFullYear()} - Rooam Inc."
		}
	}


}
