package com.rooam.resy.components.topbar

import csstype.Display
import csstype.FlexDirection
import csstype.None
import csstype.pct
import emotion.react.css
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Size
import mui.system.sx
import react.VFC
import react.dom.html.ReactHTML.div

val LoginButton = VFC {
	div {
		css {
			display = Display.flex
			flexDirection = FlexDirection.rowReverse
			width = 100.pct
		}
		Button {
			sx {
				textTransform = None.none
			}
			variant = ButtonVariant.outlined
			size = Size.small
			color = ButtonColor.secondary
			+"Log In"
		}
	}
}
