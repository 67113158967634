import co.rooam.theme.ThemesProvider
import com.rooam.resy.theme.Themes
import imported.devTools.ReactQueryDevtools
import kotlinext.js.require
import mui.material.CssBaseline
import mui.material.styles.ThemeProvider
import react.StrictMode
import react.VFC
import react.query.QueryClient
import react.query.QueryClientProvider
import react.router.dom.BrowserRouter

private val queryClient = QueryClient()
val App = VFC("Resy-Pay") {
	require("./styles/fonts.css")
	ThemesProvider.dialogTheme=Themes.Light
	StrictMode {
		QueryClientProvider {
			client = queryClient
			ThemeProvider {
				theme = Themes.Light
				CssBaseline {
					enableColorScheme = true
				}
				BrowserRouter {
					ResyRoutes {

					}
				}
			}
			ReactQueryDevtools {

			}
		}

	}
}
