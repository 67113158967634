package com.rooam.resy.components.routes.find

import com.rooam.resy.components.login.LoginBlock
import com.rooam.resy.icons.ResyIcon
import csstype.Margin
import csstype.None
import csstype.Padding
import csstype.em
import csstype.integer
import csstype.px
import emotion.react.css
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertVariant
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Container
import mui.material.Divider
import mui.material.FormControlMargin
import mui.material.FormControlVariant
import mui.material.SvgIconColor
import mui.material.SvgIconSize
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.ReactNode
import react.VFC
import react.create
import react.dom.html.ReactHTML
import react.router.dom.Link

val Find = VFC {
	Container {
	LoginBlock{

	}
	Typography {
		variant = TypographyVariant.h1
		+"Enter Check Information"
	}

	TextField {
		margin = FormControlMargin.dense
		fullWidth = true
		label = ReactNode("Check Number")
		variant = FormControlVariant.outlined
	}
	Button {
		fullWidth = true
		variant = ButtonVariant.outlined
		asDynamic().component = Link
		asDynamic().to = "../check"
		+"Find Check"
	}

}}
