package com.rooam.resy.theme

import csstype.px
import kotlinx.js.jso
import mui.material.PaletteMode
import mui.material.styles.createTheme

object Themes {
	val Light = createTheme(
		jso {
			typography = jso {
				fontFamily = "'GT America', Helvetica, Arial, sans-serif"
//				p = jso{
//					fontFamily = "'GT America',Helvetica,Arial,sans-serif"
//				}
				h1 = jso {
					fontFamily = "'Beatrice', Helvetica, Arial, sans-serif"
					fontSize = "18px"
				}
				h2 = jso {
					fontFamily = "'Beatrice', Helvetica, Arial, sans-serif"
					fontSize = "15px"
				}
				h5 = jso {
					fontFamily = "'Beatrice', Helvetica, Arial, sans-serif"
					fontSize = "15px"
					fontWeight = "600"
				}
			}
			shape = jso {
				borderRadius = 24.px
			}
			palette = jso {

				mode = PaletteMode.light
				primary = jso {
//					main = "#336dde"
//					main = "#436dee"
					main = "#16a66c"
				}
				secondary = jso {
					main = "#eb1718"
				}
				info = jso {
					main = "#000000"
				}
				success = jso {
					main = "#16a66c"
				}

			}
		}
	)
}
