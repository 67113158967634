package com.rooam.resy.components.payments;

import co.rooam.components.payment.Payment
import co.rooam.components.payment.getImageFromPayment
import csstype.AlignItems.Companion.center
import csstype.AspectRatio
import csstype.Auto
import csstype.Border
import csstype.Color
import csstype.Display
import csstype.LineStyle.Companion.solid
import csstype.Padding
import csstype.integer
import csstype.number
import csstype.pct
import csstype.px
import emotion.react.css
import imported.transitiongroup.TransitionGroup
import kotlinx.js.jso
import mui.icons.material.AddCard
import mui.icons.material.CheckCircle
import mui.icons.material.RadioButtonUnchecked
import mui.material.Box
import mui.material.Card
import mui.material.CardActionArea
import mui.material.Collapse
import mui.material.PaperVariant
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.Stack
import mui.material.SvgIconColor
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.router.useNavigate
import react.useEffect
import react.useState


external interface PaymentList : Props {
	var payments: Array<Payment>
	var selectedPayment: Payment?
	var onPaymentSelect: (Payment) -> Unit
	var showDelete: Boolean?
	var reload: () -> Unit
}


val paymentList = FC<PaymentList>("payments") { props ->
	val (selectedPayment, setPayment) = useState(props.selectedPayment)
	val navigation = useNavigate()
	useEffect(props.payments) {
		console.log(props.payments)
		props.payments.forEach {
			if (it.default) setPayment(it)
		}
	}
	Box {
		sx = jso {
			flexGrow = number(1.0)
		}

		Stack {
			spacing = responsive(1)

			TransitionGroup {
				component = null

				props.payments.forEach {
					val selected = (selectedPayment == it)
					Collapse {
						SingleRowPaymentMethod {
							payment = it
							isSelected = selected
							onPaymentSelect = {
								setPayment(it)
								props.onPaymentSelect(it)
							}
							showDelete = props.showDelete
						}
					}


				}

				Collapse {
					CardActionArea {
						Card {
							sx {
								alignItems = center
								padding = Padding(top = 6.px, right = 10.px, bottom = 6.px, left = 10.px)
								display = Display.Companion.flex
							}
							variant = PaperVariant.outlined
							onClick = {
								navigation("../addpayment")
							}
							AddCard {
								sx {
									aspectRatio = "115/81".unsafeCast<AspectRatio>()
									width = 50.px
									height = Auto.auto
									marginRight = 10.px
								}
							}
							Typography {
								sx {
									fontWeight = integer(500)
									padding = Padding(0.px, 10.px)
								}
								+"New Payment"

							}
						}
					}
				}
			}
		}
	}
}

external interface SinglePaymentMethodProps : Props {
	var payment: Payment
	var isSelected: Boolean
	var onPaymentSelect: (Payment) -> Unit
	var showDelete: Boolean?

}




val SingleRowPaymentMethod = FC<SinglePaymentMethodProps>("SinglePaymentRow") {
	var loading by useState(it.payment.type == "LOADING")
	val theme = useTheme<Theme>()
	useEffect(it.payment) {
		loading = (it.payment.type == "LOADING")
	}


	CardActionArea {
		Card {
			elevation = if (it.isSelected) 24 else 0
			sx {
				padding = Padding(top = 6.px, right = 10.px, bottom = 6.px, left = 10.px)
				display = Display.flex
				alignItems = center;
				border = if (it.isSelected) Border(1.px, solid, theme.palette.success.dark) else
					Border(1.px, solid, Color("#29292b"))
			}
			onClick = { event ->
				it.onPaymentSelect(it.payment)
			}
			if (loading) Skeleton {
				variant = SkeletonVariant.rectangular
				height = 100.pct
				img {
					css {
						width = 50.px
						borderRadius = theme.shape.borderRadius
						marginRight = 10.px
						aspectRatio = "115/81".unsafeCast<AspectRatio>()
						if (!it.isSelected) opacity = number(0.3)
					}
					src = getImageFromPayment(it.payment)

				}
			} else img {
				css {
					width = 50.px
					borderRadius = theme.shape.borderRadius
					marginRight = 10.px
					aspectRatio = "115/81".unsafeCast<AspectRatio>()
					if (!it.isSelected) opacity = number(0.3)
				}
				src = getImageFromPayment(it.payment)

			}


			Typography {
				variant = TypographyVariant.body1
				sx {
					fontWeight = integer(500)
					flexGrow = number(1.0)
				}
				if (loading) {
					Skeleton {
						variant = SkeletonVariant.text
					}
				} else
					+it.payment.label
			}
			if (it.isSelected) {
				CheckCircle {
					color = SvgIconColor.success
				}
			} else {
				RadioButtonUnchecked {

				}
			}
		}
	}
}




