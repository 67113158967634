package com.rooam.resy.components.routes.checkout

import com.rooam.resy.components.routes.pay.Summary
import com.rooam.resy.icons.ResyIcon
import components.modals.IframeModal
import csstype.AlignItems
import csstype.Border
import csstype.Color
import csstype.Display
import csstype.FlexDirection
import csstype.JustifyContent
import csstype.LineStyle
import csstype.None
import csstype.Padding
import csstype.TextAlign
import csstype.px
import csstype.rem
import emotion.react.css
import kotlinext.js.require
import kotlinx.js.jso
import mui.icons.material.Check
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.AlertVariant
import mui.material.Avatar
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Card
import mui.material.CardActions
import mui.material.CardContent
import mui.material.CardHeader
import mui.material.CardMedia
import mui.material.Collapse
import mui.material.Container
import mui.material.FormControlVariant
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputProps
import mui.material.PaperVariant
import mui.material.Size
import mui.material.SvgIconColor
import mui.material.SvgIconSize
import mui.material.TextField
import mui.material.TextFieldColor
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.material.useMediaQuery
import mui.system.sx
import react.ReactNode
import react.VFC
import react.create
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.fieldset
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.legend
import react.dom.onChange
import react.useState
import kotlin.js.json

val Checkout = VFC {
	val theme = useTheme<Theme>()
	Summary {
		Container {
			sx {
				marginBottom = 10.px
			}
			Alert {
				variant = AlertVariant.outlined
				severity = AlertColor.info
				sx {
					color = theme.palette.text.secondary
					borderColor = theme.palette.text.secondary
				}
				+"Your card will be charged for the order total and a “x” credit will appear on your American Express statement within 2-4 business days."
			}
		}
	}
	PaymentComplete {}
	FeedbackForm {}
	SendReceiptForm {}
	PayWithPoints {}
	InstallApp {}
}

val InstallApp = VFC {
	val theme = useTheme<Theme>()

	fieldset {
		css {
			borderRadius = theme.shape.borderRadius
			border = Border(1.px, LineStyle.solid, theme.palette.divider)
			textAlign = TextAlign.center
		}
		legend {
			css {
				textAlign = TextAlign.center
				border = Border(1.px, LineStyle.solid, theme.palette.divider)
				padding = Padding(10.px, 10.px)
				borderRadius = theme.shape.borderRadius
			}
			ResyIcon {
				color = SvgIconColor.secondary
			}
		}
		CardHeader {
			sx { padding = Padding(10.px, 10.px) }
			title = ReactNode("Download Resy App")
		}
		CardContent {
			sx { padding = Padding(10.px, 10.px) }

			Typography {
				+"Want to checkout even faster next time? Download the Resy App and create an account to link up your payment information."
			}
		}
		CardActions {
			Button {
				color = ButtonColor.info
				variant = ButtonVariant.contained
				fullWidth = true
				+"Apple Store"
			}
			Button {
				color = ButtonColor.info
				variant = ButtonVariant.contained
				fullWidth = true

				+"GoogleStore"
			}
		}
	}
}

val PayWithPoints = VFC {
	val theme = useTheme<Theme>()
	val amex = require("./img/hero/amex.jpg")

	Container {
		sx{
			marginBottom = 10.px
		}
		Card {
			CardMedia {
				component = img
				image = amex

			}
			CardContent {
				sx {
					textAlign = TextAlign.center
				}
				Typography {
					variant = TypographyVariant.h2
					+"Next time, use Membership Rewards® points to cover the bill."
				}
			}
			CardActions {
				Button {
					variant = ButtonVariant.contained
					size = Size.large
					fullWidth = true
					sx {
						textTransform = None.none
					}
					+"Learn More"
				}
			}
		}
	}
}

val PaymentComplete = VFC {
	val theme = useTheme<Theme>()
	ReactHTML.div {
		css {
			padding = Padding(30.px, 0.px)
			display = Display.flex
			flexDirection = FlexDirection.column
			alignItems = AlignItems.center
		}
		Avatar {
			sx {
				width = 100.px
				height = 100.px
				marginBottom = 10.px
				backgroundColor = theme.palette.augmentColor(jso {
					this.color = theme.palette.success
					lightShade = 200
				}).light
			}
			Avatar {
				sx {
					backgroundColor = theme.palette.success.main
//					Color("hsl(157, 51%, 50%)")
					width = 80.px
					height = 80.px
					color = theme.palette.background.default.unsafeCast<Color>()

				}

				Check {
					fontSize = SvgIconSize.large
					stroke = "white"
				}
			}
		}
		Typography {
			variant = TypographyVariant.h2
			+"Payment Complete"
		}
	}
}


val FeedbackForm = VFC {

	var shouldBeVisible by useState(true)
	var iframeLink by useState("")
	var showModal by useState(false)

	Collapse {
		`in` = shouldBeVisible
		Container {
			Card {
				sx {
					paddingBottom = 0.25.rem
				}
				elevation = 4
				variant = PaperVariant.outlined
				CardHeader {
					title = Typography.create {
						variant = TypographyVariant.h2
						+"How was your payment experience?"
					}


				}
				CardActions {
					sx {
						justifyContent = JustifyContent.spaceEvenly
					}
					Button {
						fullWidth = true
						color = ButtonColor.primary
						size = Size.large
						variant = ButtonVariant.outlined
						onClick = {
							iframeLink =
								"https://docs.google.com/forms/d/e/1FAIpQLSdWgtmI_0Rb16KTXm8V49aT4bJGziyjqVtv8bAm9iGyfNodGQ/viewform?embedded=true"
							showModal = true
							shouldBeVisible = false
						}
						sx {
							textTransform = None.none
						}
						+"Bad"
					}

					Button {
						fullWidth = true
						color = ButtonColor.primary
						size = Size.large
						variant = ButtonVariant.outlined
						onClick = {
							iframeLink =
								"https://docs.google.com/forms/d/e/1FAIpQLSf0qix6R0TBLIY9L0fbcyI1t6LP5JX11Zckq1m0P3r0zq-j4A/viewform?embedded=true"
							showModal = true
							shouldBeVisible = false
						}
						sx {
							textTransform = None.none
						}
						+"Great"
					}
				}
			}
		}
	}
	IframeModal {
		iframeAddress = iframeLink
		booleanShowModal = showModal
		cancelActionPressed = { showModal = false }
	}
}


val SendReceiptForm = VFC("SendReceipt") {
	val (email, setEmail) = useState("")
	val (emailSend, setEmailSend) = useState(MessageSendState.NONE)

	ReactHTML.form {
		css {
			paddingTop = 1.rem
			marginBottom = 1.rem
		}
		id = "form-receipt-send"
		Container {
			Typography {
				variant = TypographyVariant.h2
				+"Email Receipt (optional)"
			}
			Collapse {
				`in` = emailSend == MessageSendState.SUCCESS
				Alert {
					severity = AlertColor.success
					onClose = { setEmailSend(MessageSendState.NONE) }
					AlertTitle {
						+"Email Receipt Sent!"
					}
					+" Thank you. It should arrive shortly."

				}
			}
			Collapse {
				`in` = emailSend == MessageSendState.ERROR
				Alert {
					onClose = { setEmailSend(MessageSendState.NONE) }
					severity = AlertColor.error
					AlertTitle {
						+"Send Error."
					}
					+" There was an issue sending your receipt. Please try again."
				}
			}
			TextField {
				variant = FormControlVariant.outlined
				type = InputType.email
				fullWidth = true
				color = TextFieldColor.primary
				label = ReactNode("Email Address")
				value = email
				sx {
					borderBottomWidth = 2.px
				}
				onChange = { setEmail(it.target.asDynamic().value.toString()) }
				asDynamic().InputProps = jso<InputProps> {
					endAdornment = InputAdornment.create {
						position = InputAdornmentPosition.end
						Button {
							variant = ButtonVariant.contained
							size = Size.small
							color = if (emailSend == MessageSendState.SUCCESS) ButtonColor.success else
								ButtonColor.primary
							onClick = {
								setEmailSend(MessageSendState.SUCCESS)


							}
							+"Send"
						}
					}
				}
			}
		}


	}
}

enum class MessageSendState {
	NONE,
	SUCCESS,
	ERROR
}
