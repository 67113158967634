package com.rooam.resy

import com.rooam.resy.components.footer.Footer
import com.rooam.resy.components.routes.check.Check
import com.rooam.resy.components.routes.find.Find
import com.rooam.resy.components.topbar.TopBar
import com.rooam.resy.icons.ResyIcon
import csstype.AlignItems
import csstype.Display
import csstype.Margin
import csstype.None
import csstype.Padding
import csstype.em
import csstype.integer
import csstype.px
import emotion.react.css
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertVariant
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Chip
import mui.material.Container
import mui.material.Divider
import mui.material.Stack
import mui.material.SvgIconColor
import mui.material.SvgIconSize
import mui.system.responsive
import mui.system.sx
import react.VFC
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.router.Outlet
import react.router.Route
import react.router.Routes
import react.router.dom.Link

val Main = VFC("Main") {

	TopBar {

	}

		Outlet {}

	Container {
		Footer {}
	}
}


val DemoIndexRoute = VFC {
	Stack {
		spacing = responsive(2)
		Button {
			variant = ButtonVariant.contained
			asDynamic().component = Link
			asDynamic().to = "find"
			+"Find"
		}
		Button {
			variant = ButtonVariant.contained
			disabled = true
			asDynamic().component = Link
			asDynamic().to = "qr"
			+"Qr Code Scan"
		}
	}
}
