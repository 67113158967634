import com.rooam.resy.Main
import kotlinx.browser.window
import react.VFC
import react.create
import react.router.Navigate
import react.router.Route
import react.router.Routes
import react.useLayoutEffect
import react.useLayoutEffectOnce


val ResyRoutes = VFC {

	Routes {
		Route {
			path = ":placeCode"
			element = Main.create()
			Route {
				path = "check"
				element = com.rooam.resy.components.routes.check.Check.create()
			}
			Route {
				path = "find"
				element = com.rooam.resy.components.routes.find.Find.create()
			}
			Route {
				path = "pay"
				element = com.rooam.resy.components.routes.pay.Pay.create()
			}
			Route {
				path = "checkout"
				element = com.rooam.resy.components.routes.checkout.Checkout.create()
			}
			Route {
				path = "split"
				element = com.rooam.resy.components.routes.split.Split.create()
			}
			Route {
				index = true
				element = com.rooam.resy.DemoIndexRoute.create()

			}
		}
		Route {
			index = true
			element = RedirectToResyMain.create()

		}
	}
}


val RedirectToResyMain = VFC{
	useLayoutEffectOnce{
		window.location.href="https://resy.com"
	}
}

