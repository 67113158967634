package com.rooam.resy.components.topbar

import csstype.pct
import emotion.react.css
import react.VFC
import react.dom.html.ReactHTML.div

val BackButton = VFC {
	div {
		css {
			display = csstype.Display.flex
			width = 100.pct
		}
		mui.icons.material.ArrowBack {
			color = mui.material.SvgIconColor.info
		}
	}
}
