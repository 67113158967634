package com.rooam.resy.components.routes.pay

import co.rooam.components.payment.Payment
import com.rooam.resy.auth.User
import com.rooam.resy.components.expresscheckout.ExpressCheckout
import com.rooam.resy.components.payments.NewPayment
import com.rooam.resy.components.payments.paymentList
import mui.material.Container
import react.VFC

val Pay = VFC {
	Summary {}

	Container {
		ExpressCheckout {}

		if (User.isUserLoggedIn()) {
			paymentList {
				payments = arrayOf(
					ApiPayment(
						label = "Loading Payments...",
						type = "LOADING",
						default = false,
						id = "NONE",
						image = ""
					)
				)
			}
		} else {
			NewPayment {

			}
		}
	}

}

data class ApiPayment(
	override val type: String,
	override val label: String,
	override val default: Boolean,
	override val id: String,
	override val image: String

) : Payment
