package com.rooam.resy.components.routes.pay

import co.rooam.components.receipt.ReceiptLine
import co.rooam.components.receipt.RooamReceipt
import csstype.integer
import csstype.px
import csstype.rem
import emotion.react.css
import mui.material.Container
import mui.material.Divider
import mui.material.DividerVariant
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.VFC
import react.dom.html.ReactHTML.div

val Summary = FC<PropsWithChildren> {
	val theme= useTheme<Theme>()
	Container {
		sx {
			marginBottom = 20.px
		}
		disableGutters =true
		ReceiptLine {
			title = "Show Summary"
			value = "0.00"
			sx{
				fontWeight= integer(500)
				color=theme.palette.text.primary
				fontSize= 1.rem
				lineHeight = 3.rem
				marginLeft = 16.px
				marginRight = 16.px
			}
			Divider {
				variant = DividerVariant.fullWidth
			}
			Container{

			ReceiptLine {
				title = "Check"
				value = "11111"
				noDollar=true
				sx{
					color=theme.palette.text.secondary
					fontSize=1.rem
					lineHeight = 2.5.rem
				}
			}
			Divider {
				variant = DividerVariant.fullWidth
			}
			RooamReceipt{
				fees="0.00"
				tip="0.00"
				tax="0.00"
				serviceCharges="0.00"
				credit="0.00"
				subtotal="0.00"
				discount="0.00"
				taxesFees="0.00"
			}
			ReceiptLine{
				title = "Total:"
				value = "0.00"
				sx{
					fontWeight= integer(500)
					color=theme.palette.text.primary
					fontSize=1.rem
					lineHeight = 3.rem
				}
			}

		}
			+it.children
		}
		Divider {
			variant = DividerVariant.fullWidth
		}
	}
}
