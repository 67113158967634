package com.rooam.resy.components.payments

import co.rooam.utilities.md
import co.rooam.utilities.sm
import co.rooam.utilities.xs
import csstype.None
import csstype.px
import mui.icons.material.CreditCard
import mui.lab.LoadingButton
import mui.lab.LoadingPosition
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.InputLabel
import mui.material.Size
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.ReactNode
import react.VFC
import react.create
import react.dom.html.ReactHTML.div
import react.router.useNavigate

val NewPayment = VFC {
	val navigate = useNavigate()
	Typography {
		variant = TypographyVariant.h1
		sx {
			marginBottom = 18.px
		}
		+"Payment Details"
	}
	Grid {
		spacing = responsive(1)
		columns = responsive(
			Breakpoint.xs to responsive(12),
			Breakpoint.sm to responsive(12),
			Breakpoint.md to responsive(12)
		)
		sx { marginBottom = 12.px }
		container = true

		Grid {
			item = true
			xs = 12
			sm = 12
			md = 12
			TextField {
				component = div
				variant = FormControlVariant.outlined
				label = ReactNode("Credit card")
				fullWidth = true
			}
		}
		Grid {
			item = true
			xs = 6
			sm = 6
			md = 6
			TextField {
				component = div
				variant = FormControlVariant.outlined
				label = ReactNode("Expiry Date")
				fullWidth = true
			}
		}
		Grid {
			item = true
			xs = 6
			sm = 6
			md = 6
			TextField {
				component = div
				variant = FormControlVariant.outlined
				label = ReactNode("CVV")
				fullWidth = true
			}
		}
		Grid {
			item = true
			xs = 6
			sm = 6
			md = 6
			TextField {
				component = div
				variant = FormControlVariant.outlined
				label = ReactNode("Postal Code")
				fullWidth = true
			}
		}
	}
	LoadingButton {
		fullWidth = true
		variant = ButtonVariant.contained
		size = Size.large
		startIcon = CreditCard.create()
		loadingPosition = LoadingPosition.start
		sx{
			textTransform = None.none
		}
		onClick= {
			navigate("../checkout")
		}
		+"Verify Payment"
	}


}
