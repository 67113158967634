package com.rooam.resy.components.topbar

import csstype.Color
import csstype.Display
import csstype.FlexDirection
import csstype.JustifyContent
import csstype.JustifyItems
import csstype.Padding
import csstype.TextAlign
import csstype.pct
import csstype.px
import emotion.react.css
import mui.icons.material.ArrowBack
import mui.icons.material.ArrowLeft
import mui.material.AppBar
import mui.material.AppBarColor
import mui.material.AppBarPosition
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Divider
import mui.material.PaperVariant
import mui.material.Size
import mui.material.SvgIconColor
import mui.material.Toolbar
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.material.styles.useTheme
import mui.system.sx
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.svg.ReactSVG.path
import react.dom.svg.ReactSVG.svg

val TopBar = VFC("TOP_BAR") {
	val theme = useTheme<Theme>()
	AppBar {
		sx {
			backgroundColor = Color("rgba(255,255,255,1.0)")
		}
		elevation = 2
		Toolbar {
			sx {
				justifyContent = JustifyContent.spaceBetween
			}
			BackButton{
			}
			ResyLogo{ }
			LoginButton{
			}
		}
		Divider {

		}
		div {
			css {
				padding = Padding(8.px, 0.px)
				display = Display.flex
				justifyContent = JustifyContent.center
			}
			Typography {
				variant = TypographyVariant.h2
				sx {
					color = theme.palette.secondary.main
				}
				+"PLACE"
			}
		}
	}
	Toolbar {
		sx { marginBottom = 42.px }
	}

}

