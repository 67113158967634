package com.rooam.resy.components.login

import com.rooam.resy.icons.ResyIcon
import csstype.Margin
import csstype.None
import csstype.em
import csstype.integer
import csstype.px
import emotion.react.css
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertVariant
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Divider
import mui.material.SvgIconColor
import mui.material.SvgIconSize
import mui.system.sx
import react.VFC
import react.create
import react.dom.html.ReactHTML

val LoginBlock = VFC {
	Alert {
		sx {
			margin = Margin(5.px, 0.px)
		}
		severity = AlertColor.info
		variant = AlertVariant.outlined
		icon = ResyIcon.create {
			color = SvgIconColor.secondary
			fontSize = SvgIconSize.large
		}
		ReactHTML.span {
			css {
				fontWeight = integer(600)
			}
			+"Pay Faster! "
		}
		+"Log in with Resy and pay for your check even faster."
	}
	Button {
		sx {
			textTransform = None.none
		}
		variant = ButtonVariant.contained
		fullWidth = true
		+"Log In With Resy"

	}

	Divider {
		sx {
			padding = csstype.Padding(20.px, 0.px)
			lineHeight = 1.em
		}
		com.rooam.resy.icons.ResyIcon {
			color = mui.material.SvgIconColor.disabled
			fontSize = mui.material.SvgIconSize.small
		}

	}
}
